/* Global styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f9; /* Soft pastel background */
    margin: 0;
    padding: 0;
}

/* Typography */
h1, h2, h3 {
    color: #333;
}

p {
    color: #555;
}

/* Main Wishlist and Admin Container */
.main-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

/* Wishlist Item Styles */
.wishlist-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex; /* Use flexbox to layout the content */
    align-items: center; /* Align items vertically center */
    gap: 10px; /* Spacing between child elements */
}

.wishlist-item h3 {
    color: #6a5acd; /* Slightly deeper pastel shade */
    font-size: 1rem; /* Adjust the font size */
    margin: 0; /* Remove margin */
}

.wishlist-item.reserved {
    background-color: #ffdfd3; /* A light pastel peach color for reserved items */
    border: 1px solid #ff9a8b; /* A slightly darker border for contrast */
    color: #6a5acd; /* Adjust the text color if needed */
}

.wishlist-item.reserved h3 {
    color: #d38383; /* A soft color for the title in reserved items */
}

.wishlist-item img {
    max-width: 60px; /* Adjust the image size */
    height: auto;
    border-radius: 8px;
}

.wishlist-item p {
    color: #333;
    font-size: 0.8rem; /* Adjust the font size */
    margin: 0; /* Remove margin */
}

.wishlist-item button {
    align-self: flex-end;
    flex-shrink: 0;
    margin: auto 0;
}

.wishlist-item-content {
    flex-grow: 1;
}

.reserved-label {
    background-color: #cccccc; /* Gray background for a 'disabled' look */
    color: #666666; /* Darker text */
    padding: 10px 20px; /* Similar padding to buttons */
    font-size: 14px;
    border-radius: 5px; /* Rounded corners */
    text-align: center; /* Center the text */
    display: inline-block; /* Make it inline like a button */
    margin: auto 0; /* Margin for spacing */
}


/* Wishlist Item Action Styles */
.wishlist-item .item-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.wishlist-item .edit-icon,
.wishlist-item .delete-icon {
    cursor: pointer;
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    border: 2px solid transparent;
    border-radius: 4px;
    line-height: 1;
    width: 30px;
    height: 30px;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s, box-shadow 0.2s, border-color 0.2s;
}

.wishlist-item .edit-icon:hover,
.wishlist-item .delete-icon:hover {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    border-color: #ddd;
}

.wishlist-item .edit-icon {
    color: #4caf50;
}

.wishlist-item .delete-icon {
    color: #f44336;
}

/* Style for input fields */
.login-input, .add-item-form input, .add-item-form textarea {
    font-size: 16px;
    width: 100%; /* Full width */
    max-width: 500px;
    padding: 10px; /* Comfortable padding */
    margin: 5px 0; /* Spacing between fields */
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 4px; /* Slightly rounded corners */
    box-sizing: border-box; /* Include padding and border in width */
}

/* Style for the login button */
.login-button, .add-item-form button {
    width: 100%; /* Full width */
    max-width: 500px;
    padding: 10px; /* Comfortable padding */
    margin-top: 10px; /* Spacing above the button */
    background-color: #4CAF50; /* A nice green */
    color: white; /* White text */
    border: none; /* No border */
    border-radius: 4px; /* Slightly rounded corners */
    cursor: pointer; /* Change cursor to indicate it's clickable */
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.login-button:hover, .add-item-form button:hover {
    background-color: #45a049; /* Slightly darker green on hover */
}

/* Additional Style for the cancel button */
.add-item-form .cancel-button {
    background-color: #f44336; /* Red background */
    color: white; /* White text for better readability */
    border: none; /* Remove border */
    padding: 10px 20px; /* Padding for the button */
    border-radius: 4px; /* Slightly rounded corners */
    cursor: pointer; /* Cursor pointer on hover */
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.add-item-form .cancel-button:hover {
    background-color: #d32f2f; /* Darker shade of red on hover */
}

/* Add Item Form Styles */
.add-item-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.add-item-form form {
    max-width: 600px;
}

.add-item-form textarea {
    height: 100px; /* Adjust the height of textarea */
}

/* Login Form Styles */
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 300px; /* Adjust width as needed */
}

.login-form .error-message, .add-item-form .error-message {
    color: red;
    margin-top: 10px;
}

/* Buttons and Forms */
button, input[type="submit"] {
    background-color: #779ecb; /* Light pastel blue */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

button:hover, input[type="submit"]:hover {
    background-color: #9fb6cd;
}

input[type="text"], textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

/* Admin Panel Styles */
.admin-panel {
    /* background-color: #f0f8ff; /* Very light pastel blue */
    padding: 0;
    border-radius: 10px;
}



/* Responsiveness */
@media (max-width: 768px) {
    .main-container {
        width: 95%;
    }
}
